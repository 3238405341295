import axios from 'axios';

export const apiFunction = {
  // Get all segments in segment page
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // **
  // ***
  getUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Save user segment in segment page
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "segmentId": "string" (if with segmentId , edit the segment)
  // "segmentName": "string"
  // "segmentConditions": "Array"
  // "segmentUsers": "Array"
  // **
  // ***
  saveUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "saveUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Delete user segment in segment page
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // "segmentId": "string"
  // **
  // ***
  deleteUserSegment: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "deleteUserSegment",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Show Tag list in create segment page
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getTag: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getTag",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get users from chat history
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getChatHistory: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getChatHistory",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },
  // Get userlist
  // ***required fields
  // **
  // "adminId": "string"
  // "accountId": "string"
  // ***
  getUserList: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getUsersList",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e);
      return {error: e.response ? e.response : "null"}
    });
  },
  // Get variables
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getVariable: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getVariable",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Get all scenario for broadcast item
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // **
  // ***
  getScenario: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getScenario",
      data: formData,
      headers: {
          Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

  // Create broadcast plan in segment
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string",
  // "broadcastId": "string",
  // "broadcastName": "string",
  // "broadcastScenarioName": "string",
  // "broadcastScenarioId": "string",
  // "broadcastUsers": [
  //     {
  //         "userId": "string",
  //         "userName": "string",
  //         "userPicture": "string",
  //         "accountType": "string",
  //         "accountStatus": "string",
  //         "followTime": "string",
  //         "createTime": "string"
  //     }
  // ],
  // "broadcastTime": "2020-05-18 23:58:19",
  // "broadcastStatus": "pending",
  // "segmentName": "list"
  // **
  // ***
  scheduleBroadcast: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "scheduleBroadcast",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
  /**
   * Access LINE API in backend to get all followers.
   * @param {*} formData 
   */
  getFollowers: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "scheduleUsersImport",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },

};
