<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th style="cursor: pointer;" @click="onClickTagNameColumnHeader">
        {{this.$t("__TagName")}} {{ getSortOrderLabel(tagNameColumnSortState) }}
      </th>
      <th>{{this.$t("__TagColor")}}</th>
      <th>Values</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td align="center" style="width: 90px">
            <label :class="item.color" class="m-auto color-label"></label>
          </td>
          <td>{{ item.values }}</td>
          <td style="width: 80px">
            <a
              href="javascript:void(0)"
              class="editStoryIcon"
              @click="editTag(item)"
            >
              <i class="fas fa-edit mx-1"></i>
            </a>
            <a href="javascript:void(0)" @click="deleteTag(item)">
              <i class="fas fa-trash text-danger mx-1"></i>
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "tagList-table",
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // テーブルのソート状態（none, asc, desc）
      tagNameColumnSortState: 'none',
    }
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
  },
  methods: {
    // Example: 2020-06-29 09:10
    toFrontendTimeFormat(dateTime) {
      return this.$dateTime.toFrontEndTimeFormat(dateTime);
    },
    // Status type: 0, 1
    accountStatus(status) {
      if (status === "0") {
        return '<span class="text-danger">' + this.$t("__blockade") + "</span>";
      }
      return '<span class="text-success">' + this.$t("__Active") + "</span>";
    },
    editTag(tag) {
      this.$emit("editTag", tag);
    },
    deleteTag(tag) {
      this.$emit("deleteTag", tag);
    },
    getSortOrderLabel(order) {
      if (order == 'none') return '';
      return order == 'asc' ? '▲' : '▼';
    },
    onClickTagNameColumnHeader() {
      this.tagNameColumnSortState = (this.tagNameColumnSortState == 'asc' ? 'desc' : 'asc');
      this.sortTableByTagNameColumn();
    },
    sortTableByTagNameColumn() {
      if (this.tagNameColumnSortState == 'none') return;
      const ascFunc = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      const descFunc = (a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      }
      this.data.sort(this.tagNameColumnSortState == 'asc' ? ascFunc : descFunc);
    }
  },
};
</script>
<style>
</style>
