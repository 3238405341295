<template>
  <div class>
    <h1>Admin Login</h1>
    <form @submit.prevent="login">
      <label>{{ this.$t("__userName") }}</label>
      <input type="text" class="form-control" v-model="userName" required />
      <br />
      <label>{{ this.$t("__password") }}</label>
      <input type="password" class="form-control" v-model="password" required />
      <br />
      <button type="submit">{{ this.$t("__loginButton") }}</button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
    };
  },
  methods: {
    async login() {
      //-- write login authencation logic here! --
      if (await this.validation(this.userName, this.password)) {
        this.$router.push("/account");
      } else {
        this.$alert(this.$t("__loginFailed"));
      }
    },
    async validation(account, password) {
      try {
        const user = await this.$Amplify.Auth.signIn(account, password);
        return true;
      } catch (e) {
        console.log("error signing in: ", e);
      }
      return false;
    },
  },
};
</script>
