<template>
  <card class="main-content">
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input
          id="search-box"
          type="text"
          class="search-box"
          name="q"
          v-model="search"
        />
        <label for="search-box">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <!-- content start -->
    <div class="row mt-4 mb-1">
      <div class="ml-auto mr-4">
        <a
          href="javascript:void(0)"
          style="color: #009688"
          @click="$router.push('/segment')"
          >{{ $t("__Userclassification") }}</a
        >
        <span class="px-2">/</span>
        <a
          href="javascript:void(0)"
          @click="$router.push('/segment/userList')"
          >{{ $t("__Userlist") }}</a
        >
      </div>
    </div>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" class="table-responsive">
      <segment-table
        type="hover table-bordered"
        :adminId="adminId"
        :jwtToken="jwtToken"
        :accountId="accountId"
        :data="table.data"
        :columns="table.columns"
        v-bind:newSegment="modalData"
        v-bind:currentNumber="allSegment.length"
      ></segment-table>
      <!-- content end -->
      <pagination
        v-model="page"
        :per-page="rowPerPage"
        :records="allSegment.length"
        :options="{
          texts: {
            count: this.$t('__paginationText'),
          },
        }"
        @paginate="callback"
      ></pagination>
    </div>
  </card>
</template>
<script>
import { CreatePushModal } from "./Modals/index";
import { SegmentTable } from "./Components/index";
import { apiFunction } from "./Axios/functions";

const tableData = [];

export default {
  components: {
    SegmentTable,
    CreatePushModal,
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val);
        let searchResult = [];

        $.each(this.allSegment, function (i, segment) {
          $.each(segment.tags, function (i, tag) {
            if (reg.test(segment.name) == false && reg.test(tag.name) == true) {
              searchResult.push(segment);
            }
          });
          if (reg.test(segment.name) == true) {
            searchResult.push(segment);
          }
        });
        this.table.data = searchResult;
      },
    },
  },
  data() {
    return {
      isLoading: false,
      search: "",
      page: 1,
      rowPerPage: 20,
      allSegment: [],
      totalSegment: 0,
      adminId: "",
      jwtToken: "",
      accountId: "",
      table: {
        columns: [],
        data: [...tableData],
      },
      modalData: {
        segmentName: "",
        segmentConditions: [],
        segmentUsers: [],
        modalBtn: this.$t("__setup"),
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }

      let formData = { adminId: this.adminId, accountId: this.accountId };
      this.isLoading = true;
      apiFunction.getUserSegment(formData, this.jwtToken).then((data) => {
        this.isLoading = false;
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.table.data = this.sortByUpdateTime(data.data.Result.data);
          this.allSegment = this.sortByUpdateTime(data.data.Result.data);
          // Init pagination
          this.callback(this.page);
        }
      });
    },
    // Pagination
    callback(pageNum) {
      let arr = this.allSegment.slice(
        this.rowPerPage * (pageNum - 1),
        this.rowPerPage * pageNum
      );
      this.table.data = arr;
    },
    // Sort table row by update time
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.updateTime),
          dateB = new Date(b.updateTime);
        return dateA < dateB ? 1 : -1;
      });
      return array;
    },
  },
};
</script>
<style>
</style>
