var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", attrs: { id: "account-block-area" } },
    _vm._l(_vm.data, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "col-3 m-3 border rounded-lg account-block shadow",
          on: {
            click: function($event) {
              return _vm.enterPage(
                item.accountId,
                item.accountName,
                item.accountType,
                item.accountIcon,
                item.accountToken
              )
            }
          }
        },
        [
          _c("img", {
            staticClass: "rounded-circle account-type",
            class: _vm.connectTypeClass(item.accountType),
            attrs: { src: _vm.connectType(item.accountType) }
          }),
          _c("div", { staticClass: "text-center pt-4" }, [
            _c("img", {
              staticClass: "rounded-circle",
              staticStyle: { width: "150px", height: "150px" },
              attrs: { src: _vm.checkAccountIcon(item.accountIcon) }
            })
          ]),
          _c("p", { staticClass: "text-center py-3 font-weight-bold" }, [
            _vm._v(_vm._s(item.accountName))
          ])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }