var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "variable",
        tabindex: "-1",
        role: "dialog",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-title text-bold" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.$t("__variable")) + "\n        "
                )
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "ml-auto py-2 pr-0" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchVariable,
                            expression: "searchVariable"
                          }
                        ],
                        staticClass: "search-box",
                        attrs: { type: "text" },
                        domProps: { value: _vm.searchVariable },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.searchVariable = $event.target.value
                          }
                        }
                      }),
                      _vm._m(1),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary btn-sm",
                          attrs: {
                            "data-dismiss": "modal",
                            "data-toggle": "modal",
                            "data-target": "#create-variable-modal"
                          }
                        },
                        [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("__AddVariable")) +
                              "\n                "
                          )
                        ]
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "row border mb-3 py-2 px-3",
                      staticStyle: {
                        "overflow-y": "scroll",
                        "max-height": "15rem"
                      }
                    },
                    [
                      _vm._l(_vm.variables, function(variable, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "input-group mb-1" },
                          [
                            _c("div", { staticClass: "input-group-prepend" }, [
                              _c("div", { staticClass: "input-group-text" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.checkedVariables,
                                      expression: "checkedVariables"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    id: "variable" + index
                                  },
                                  domProps: {
                                    value: variable,
                                    checked: Array.isArray(_vm.checkedVariables)
                                      ? _vm._i(_vm.checkedVariables, variable) >
                                        -1
                                      : _vm.checkedVariables
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = _vm.checkedVariables,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = variable,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              (_vm.checkedVariables = $$a.concat(
                                                [$$v]
                                              ))
                                          } else {
                                            $$i > -1 &&
                                              (_vm.checkedVariables = $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1)))
                                          }
                                        } else {
                                          _vm.checkedVariables = $$c
                                        }
                                      },
                                      _vm.selectedOnChanged
                                    ]
                                  }
                                })
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "py-2 border rounded col" },
                              [
                                _c("span", [_vm._v(_vm._s(variable))]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "pull-right",
                                    attrs: { href: "javascript:void(0)" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeVariable(variable)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fas fa-trash text-danger mx-1"
                                    })
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      _vm.variables.length == 0
                        ? _c("p", { staticClass: "m-auto py-4" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("__Novariablesavailable")) +
                                "\n              "
                            )
                          ])
                        : _vm._e()
                    ],
                    2
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button", "data-dismiss": "modal" },
                  on: {
                    click: function($event) {
                      return _vm.addExistVariables()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("__determine")) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "fas fa-search search-icon" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }