var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", [
    _c("div", [
      _c("div", { staticClass: "row" }, [
        _vm.$slots.header
          ? _c("div", { staticClass: "col-5" }, [_vm._t("header")], 2)
          : _vm._e(),
        _vm.$slots.content
          ? _c("div", { staticClass: "col-7" }, [_vm._t("content")], 2)
          : _vm._e()
      ]),
      _vm.$slots.footer ? _c("div", [_c("hr"), _vm._t("footer")], 2) : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }