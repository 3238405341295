<template>
  <!-- modal start -->
  <div
    class="modal fade"
    id="createAccount"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content tag-modal">
        <div class="modal-header">
          <p
            class="modal-title text-bold"
            id="exampleModalCenterTitle"
            data-translate="_send.setting"
          >
            {{ $t("__Createnewlink") }}
          </p>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <!-- Loading spinner -->
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border">
                <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-if="!isLoading" class="container-fluid">
            <div class="row mb-2">
              <span class="text-danger">*</span> {{ $t("__requiredItem") }}
            </div>
            <div class="row mb-2">
              <!-- default account info -->
              <input
                type="hidden"
                class="form-control"
                v-model="formInput.adminId.value"
                :class="{ 'is-invalid': formInput.adminId.error }"
              />
              <input
                type="hidden"
                class="form-control"
                v-model="formInput.accountWebhookUrl.value"
                :class="{ 'is-invalid': formInput.accountWebhookUrl.error }"
              />

              <!-- account id -->
              <label class="col-4 pt-2" data-translate="_send.setting">{{
                $t("__accountId")
              }}</label>
              <p class="mb-2 col-7" id="story-create-title">
                {{ formInput.accountId.value }}
              </p>
              <span id="story-create-edit" @click="editName()">
                <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
              </span>
              <input
                type="text"
                class="form-control mb-2 col-8 element-hide"
                id="story-create-input"
                v-model="formInput.accountId.value"
                @keypress="confirmChangeName($event)"
                :class="{ 'is-invalid': formInput.accountId.error }"
              />
              <!-- account name -->
              <label class="col-4 pt-2" data-translate="_send.setting">
                <span class="text-danger">*</span> {{ $t("__name") }}
              </label>
              <input
                id="account-name"
                type="text"
                class="form-control mb-2 col-8"
                v-model="formInput.accountName.value"
                :class="{ 'is-invalid': formInput.accountName.error }"
              />

              <!-- account type -->
              <label class="col-4 pt-2" data-translate="_send.setting">{{
                $t("__ConnectionType")
              }}</label>
              <select
                class="custom-select mb-2 col-8"
                id="account-type"
                @change="changeWebhook()"
                v-model="formInput.accountType.value"
                :class="{ 'is-invalid': formInput.accountType.error }"
              >
                <!-- <option></option> -->
                <option value="line">{{ $t("__line") }}</option>
                <option value="wechat">{{ $t("__wechat") }}</option>
                <option value="fb">{{ $t("__facebook") }}</option>
              </select>

              <!-- account clone -->
              <label class="col-4 pt-2">{{ $t("__accountClone") }}</label>
              <select
                class="custom-select mb-2 col-8"
                id="account-clone"
                v-model="formInput.accountClone.value"
              >
                <option value="">Choose Account</option>
                <option
                  :value="account.accountId"
                  v-for="(account, idx) in allAccounts"
                  :key="idx"
                >
                  {{ account.accountName }}
                </option>
              </select>

              <div class="col-12 pt-4">
                <span class="font-weight-bold ml-auto">
                  {{ $t("__webhookUrl") }} :
                  <span style="font-size: 12px">{{
                    formInput.accountWebhookUrl.value
                  }}</span>
                  <span
                    v-if="isSupportClipboardApi"
                    @click="onClickCopyWebhookUrl"
                  >
                    <i class="fas fa-copy cursor-pointer mx-2" />
                  </span>
                </span>
              </div>

              <!-- line -->
              <div
                class="col-12 py-2 px-4 my-3"
                id="line-block"
                v-if="formInput.accountType.value == 'line'"
              >
                <label class>
                  <span class="text-danger">*</span>
                  {{ $t("__channelSecret") }}:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountSecret.value"
                  :class="{ 'is-invalid': formInput.accountSecret.error }"
                />
                <label class>
                  <span class="text-danger">*</span>
                  {{ $t("__channelAccessToken") }}:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountToken.value"
                  :class="{ 'is-invalid': formInput.accountToken.error }"
                />
              </div>

              <!-- wechat -->
              <div
                class="col-12 py-4 px-4 my-3"
                id="wechat-block"
                v-if="formInput.accountType.value == 'wechat'"
              >
                <label class> <span class="text-danger">*</span> appid: </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountAppId.value"
                  :class="{ 'is-invalid': formInput.accountAppId.error }"
                />
                <label class>
                  <span class="text-danger">*</span> appsecret:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountSecret.value"
                  :class="{ 'is-invalid': formInput.accountSecret.error }"
                />
                <label class>
                  <span class="text-danger">*</span> app token:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountToken.value"
                  :class="{ 'is-invalid': formInput.accountToken.error }"
                />
              </div>

              <!-- Facebook -->
              <div
                class="col-12 py-2 px-4 my-3"
                id="line-block"
                v-if="formInput.accountType.value == 'fb'"
              >
                <label class>
                  <span class="text-danger">*</span> appsecret:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountSecret.value"
                  :class="{ 'is-invalid': formInput.accountSecret.error }"
                />
                <label class>
                  <span class="text-danger">*</span> app token:
                </label>
                <input
                  class="form-control"
                  type="text"
                  v-model="formInput.accountToken.value"
                  :class="{ 'is-invalid': formInput.accountToken.error }"
                />
              </div>
              <hr />

              <!-- Upload image input-->
              <div class="col-12 pb-4 my-1">
                <label>{{ $t("__Accounticon") }}</label>
                <div class="border">
                  <vue-dropzone
                    ref="myVueDropzone"
                    id="dropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-success="showSuccess"
                    v-on:vdropzone-removed-file="removeFile"
                  ></vue-dropzone>
                </div>
              </div>
            </div>

            <div class="row">{{ formInput.errorText }}</div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="account-create-btn"
            type="button"
            class="btn btn-outline-secondary"
            style="width: 100%"
            data-translate="_send.setting"
            @click="createAccount()"
          >
            {{ $t("__establishconnection") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal end -->
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { apiFunction } from "../Axios/functions";

export default {
  name: "create-account",
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    webhookUrl: String,
    allAccounts: Array
  },
  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t("__Clickordragtoupload") +
      "</h4></div>";
    const cancelText = this.$t("__cancelupload");
    return {
      isLoading: false,
      token: null,
      imgPath: "",
      isActive: false,
      formInput: {
        adminId: { value: this.adminId, error: false },
        accountId: { value: this.accountId, error: false },
        accountName: { value: "", error: false },
        accountType: { value: "line", error: false },
        accountIcon: { value: "https://i.imgur.com/z3IyRTk.jpg", error: false },
        accountWebhookUrl: { value: this.webhookUrl, error: false },
        accountToken: { value: "", error: false },
        accountSecret: { value: "", error: false },
        accountAppId: { value: "", error: false },
        accountClone: { value: "", error: false },
        errorText: "",
      },
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: false,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
        dictCancelUploadConfirmation: cancelText,
      },
    };
  },
  watch: {
    formInput: {
      handler: function (newVal) {
        if (newVal.accountId.value != "") {
          this.accountId = newVal.accountId.value;
          this.changeWebhook();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$watch(
      "accountId",
      (accountId) => {
        this.loadData();
      },
      { immediate: true }
    );
  },
  methods: {
    loadData() {
      if (!localStorage.imgur) {
        this.getAccessToken();
      }
      $("#createAccount").modal("show");
    },
    // Get imgur access token
    async getAccessToken() {
      let client_id = "5f0e24fff917836";
      let secret = "43209cf81d4d78431c31880041f2e1b2a8267ad0";
      let refresh_token = "5bc66b5547b6d7536bbb674c1eaa2c4a79a9d24f";

      let formData = new FormData();
      formData.append("refresh_token", refresh_token); //required
      formData.append("client_id", client_id);
      formData.append("client_secret", secret);
      formData.append("grant_type", "refresh_token");

      await this.axios({
        method: "POST",
        url: "https://api.imgur.com/oauth2/token",
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // Imgur upload function
    async handleFileUpload(file) {
      if (!localStorage.imgur) {
        await this.getAccessToken();
      }
      let token = localStorage.imgur;
      this.file = file[0];

      let formData = new FormData();
      formData.append("image", this.file); //required
      formData.append("title", "test"); //optional
      formData.append("description", "test"); //optional

      this.axios({
        method: "POST",
        url: "https://imgur-apiv3.p.rapidapi.com/3/image",
        data: formData,
        headers: {
          "x-rapidapi-host": "imgur-apiv3.p.rapidapi.com",
          "x-rapidapi-key":
            "80e506eca9msh9b8408b66966ac1p11adfbjsnb28295cec40e",
          authorization: "Bearer " + token,
          "content-type": "application/x-www-form-urlencoded",
        },
        mimeType: "multipart/form-data",
      })
        .then((res) => {
          this.imgPath = res.data.data.link;
          // console.log(this.imgPath);
        })
        .catch((e) => {
          alert(this.$t("__Imagechangeerror"));
          localStorage.removeItem("imgur");
        });
    },
    showSuccess($event) {
      this.$refs.myVueDropzone.disable();
      this.handleFileUpload(this.$refs.myVueDropzone.getAcceptedFiles());
    },
    removeFile() {
      this.$refs.myVueDropzone.enable();
    },

    // Account type selected
    changeWebhook() {
      if (this.formInput.accountType.value == "line") {
        this.formInput.accountWebhookUrl.value =
          process.env.VUE_APP_API_ENDPOINT +
          "api/LineMessage/send?adminid=" +
          this.adminId +
          "&accountid=" +
          this.formInput.accountId.value;
      }else if (this.formInput.accountType.value == "wechat") {
        this.formInput.accountWebhookUrl.value =
          process.env.VUE_APP_API_ENDPOINT +
          "api/wechat?adminid=" +
          this.adminId +
          "&accountid=" +
          this.formInput.accountId.value;
      }else if (this.formInput.accountType.value == "fb") {
        this.formInput.accountWebhookUrl.value =
          process.env.VUE_APP_API_ENDPOINT +
          "api/facebook?adminid=" +
          this.adminId +
          "&accountid=" +
          this.formInput.accountId.value;
      }
    },

    // TODO add validation
    // Form Validation
    formCheck() {
      let status = true;
      //reset error
      this.formInput.accountName.error = false;
      this.formInput.accountType.error = false;
      this.formInput.accountAppId.error = false;
      this.formInput.accountSecret.error = false;
      this.formInput.accountToken.error = false;

      var pattern = "";
      //长度为1-20的所有字符
      // pattern = new RegExp('^.{1,20}$');
      if (!this.formInput.accountName.value) {
        this.formInput.accountName.error = true;
        status = false;
      }

      // 有值
      if (!this.formInput.accountType.value) {
        this.formInput.accountType.error = true;
        status = false;
      }

      //wechat app id
      if (
        this.formInput.accountType.value == "wechat" &&
        !this.formInput.accountAppId.value
      ) {
        this.formInput.accountAppId.error = true;
        status = false;
      }

      // 有值
      if (!this.formInput.accountSecret.value) {
        this.formInput.accountSecret.error = true;
        status = false;
      }

      // 有值
      if (!this.formInput.accountToken.value) {
        this.formInput.accountToken.error = true;
        status = false;
      }

      return status;
    },

    createAccount() {
      // If pass form validation
      if (this.formCheck()) {
        this.formInput.adminId.value = this.adminId;
        this.formInput.accountId.value = this.accountId;
        // Add image path if uploaded
        if (this.imgPath != "") {
          this.formInput.accountIcon.value = this.imgPath;
        }

        let formData = {
          adminId: this.formInput.adminId.value,
          accountId: this.formInput.accountId.value,
          accountName: this.formInput.accountName.value,
          accountType: this.formInput.accountType.value,
          accountIcon: this.formInput.accountIcon.value,
          accountWebhookUrl: this.formInput.accountWebhookUrl.value,
          accountToken: this.formInput.accountToken.value,
          accountSecret: this.formInput.accountSecret.value,
          accountClone: this.formInput.accountClone.value
        };

        // If account type is wechat, add app id
        if (this.formInput.accountType.value == "wechat") {
          formData.appId = this.formInput.accountAppId.value;
        }
        this.isLoading = true;
        apiFunction.createAccount(formData, this.jwtToken).then((data) => {
          this.isLoading = false;
          if (data.status == 200) {
            this.$fire({
              title: this.$t("__addedsuccessfully"),
              text: this.$t("__Anewaccounthasbeensuccessfullycreated"),
              type: "success",
              timer: 1500,
            }).then((e) => {
              this.$router.go();
            });
          } else {
            this.$fire({
              title: this.$t("__Addfailed"),
              text: this.$t("__oopsSomethingwentwrong"),
              type: "error",
            });
          }
        });
      }
      // Form validate fail
      else {
        this.$alert(this.$t("__dataMissing"));
      }
    },

    // Story page push message title edit
    editName() {
      $("#story-create-title").hide();
      $("#story-create-input").show();
      $("#story-create-edit").hide();
    },
    // Story page push message title submit
    confirmChangeName($event) {
      if ($event.which == 13) {
        $("#story-create-title").show();
        $("#story-create-input").hide();
        $("#story-create-edit").show();
        return false;
      }
    },
    isSupportClipboardApi() {
      return navigator.clipboard != undefined;
    },
    onClickCopyWebhookUrl() {
      navigator.clipboard.writeText(this.webhookUrl).then(() => {
        this.$fire({
          text: this.$t("__webhookUrlCopied"),
          type: "success",
        });
      });
    },
  },
};
</script>
<style>
</style>
