var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "nav nav-tabs", attrs: { id: "nav-fb-multi-temp-tab" } },
      [
        _vm._l(_vm.fbGenericElements, function(element, fbElemIndex) {
          return _c(
            "li",
            {
              key: "nav-template-" + fbElemIndex + "-tab",
              staticClass: "nav-item"
            },
            [
              fbElemIndex == 0
                ? _c(
                    "button",
                    {
                      staticClass: "nav-link active",
                      attrs: {
                        id: "nav-template-" + fbElemIndex + "-tab",
                        "data-toggle": "tab",
                        "data-target": "#nav-template-" + fbElemIndex,
                        role: "tab",
                        "aria-controls": "nav-template-" + fbElemIndex,
                        "aria-selected": "true",
                        "aria-current": "page"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("__template")) +
                          " " +
                          _vm._s(fbElemIndex + 1) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              fbElemIndex > 0
                ? _c(
                    "button",
                    {
                      staticClass: "nav-link",
                      attrs: {
                        id: "nav-template-" + fbElemIndex + "-tab",
                        "data-toggle": "tab",
                        href: "#nav-template-" + fbElemIndex,
                        role: "tab",
                        "aria-controls": "nav-template-" + fbElemIndex,
                        "aria-selected": "false"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("__template")) +
                          " " +
                          _vm._s(fbElemIndex + 1) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        }),
        _vm.fbGenericElements.length < 10
          ? _c("li", { staticClass: "nav-item" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link text-danger",
                  attrs: { id: "nav-add-template-tab", href: "#", role: "tab" },
                  on: {
                    click: function($event) {
                      return _vm.addNewFbTemplate()
                    }
                  }
                },
                [_vm._v("＋")]
              )
            ])
          : _vm._e()
      ],
      2
    ),
    _c(
      "div",
      {
        staticClass: "tab-content",
        attrs: { id: "nav-fb-multi-temp-tabContent" }
      },
      _vm._l(_vm.fbGenericElements, function(element, fbElemIndex) {
        return _c(
          "div",
          {
            key: "nav-template-" + fbElemIndex + "-tabContent",
            staticClass: "tab-pane fade",
            class: {
              "show active": fbElemIndex === 0
            },
            attrs: {
              id: "nav-template-" + fbElemIndex,
              role: "tabpanel",
              "aria-labelledby": "nav-template-" + fbElemIndex + "-tab"
            }
          },
          [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "float-right mt-2 mr-2" }, [
                _c(
                  "a",
                  {
                    staticClass: "text-danger float-right",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        return _vm.deleteFbTemplate(fbElemIndex)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-trash text-danger" })]
                )
              ]),
              _c("div", { staticClass: "card-body" }, [
                _c("h5", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.$t("__image")))
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-4" }, [
                    _c("div", { staticClass: "form-check pb-4 my-1" }, [
                      _c(
                        "div",
                        { staticClass: "border" },
                        [
                          _c("vue-dropzone", {
                            ref: "fb-multi-temp-dropzone-" + fbElemIndex,
                            refInFor: true,
                            attrs: {
                              id: "fb-multi-temp-dropzone-" + fbElemIndex,
                              options: _vm.dropzoneOptions
                            },
                            on: {
                              "vdropzone-file-added": function($event) {
                                return _vm.fbGenerateTempsImgHandling(
                                  $event,
                                  fbElemIndex
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-8" }, [
                    _c("div", { staticClass: "form-check" }, [
                      _c("label", [_vm._v(_vm._s(_vm.$t("__urltitle")))]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: element.title,
                            expression: "element.title"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "text", id: "image-title" },
                        domProps: { value: element.title },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(element, "title", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-check pt-3" }, [
                      _c("label", [
                        _vm._v(" " + _vm._s(_vm.$t("__urlupload")))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: element.imgUrl,
                            expression: "element.imgUrl"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "url-pic-upload",
                          placeholder: _vm.$t("__urlPlaceHolder")
                        },
                        domProps: { value: element.imgUrl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(element, "imgUrl", $event.target.value)
                          }
                        }
                      })
                    ]),
                    _c("hr"),
                    _c("div", { staticClass: "form-check" }, [
                      _c("label", [
                        _vm._v(" " + _vm._s(_vm.$t("__urllinkoptional")))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: element.webUrl,
                            expression: "element.webUrl"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          id: "url-pic-transition",
                          placeholder: _vm.$t("__urlPlaceHolder")
                        },
                        domProps: { value: element.webUrl },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(element, "webUrl", $event.target.value)
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h5", { staticClass: "card-title" }, [
                    _vm._v(_vm._s(_vm.$t("__Button")))
                  ]),
                  _vm._l(element.buttons, function(item, btnIndex) {
                    return _c(
                      "div",
                      { key: btnIndex, staticClass: "border p-2 mb-2" },
                      [
                        _c("div", { staticClass: "float-right" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-danger float-right",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function($event) {
                                  return _vm.deleteButton(btnIndex)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-trash text-danger"
                              })
                            ]
                          )
                        ]),
                        _c("br"),
                        _c("div", { staticClass: "form-row px-4" }, [
                          _c("div", { staticClass: "form-group col-4" }, [
                            _c("label", [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*")
                              ]),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("__Textcontent"))
                              )
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.action.label,
                                  expression: "item.action.label"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: { value: item.action.label },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    item.action,
                                    "label",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _c("div", { staticClass: "px-4 mt-2 mb-3" }, [
                          _c("label", [_vm._v(_vm._s(_vm.$t("__label")))]),
                          _c("div", { staticClass: "form-row" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.tag,
                                    expression: "item.tag"
                                  }
                                ],
                                staticClass: "col-3 form-control ml-2",
                                attrs: { id: "btntag" },
                                on: {
                                  focus: function($event) {
                                    return _vm.buttonTagOnchanged(
                                      fbElemIndex,
                                      btnIndex
                                    )
                                  },
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      item,
                                      "tag",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "", selected: "" },
                                    domProps: {
                                      value: {
                                        color: "",
                                        name: ""
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.$t("__TagSelectOptional")) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _vm._l(_vm.tagsInput, function(tag, btnIndex) {
                                  return _c(
                                    "option",
                                    {
                                      key: 100 + btnIndex,
                                      domProps: { value: tag }
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(tag.name) +
                                          "\n                  "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: item.tagValue,
                                    expression: "item.tagValue"
                                  }
                                ],
                                staticClass: "col-3 form-control ml-2",
                                attrs: {
                                  "aria-placeholder": _vm.$t("__fontcolor")
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      item,
                                      "tagValue",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "",
                                      disabled: "",
                                      selected: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t("__TagValueSelectOptional")
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                ),
                                _vm._l(
                                  _vm.valuesOfTag(
                                    item.tag.color,
                                    item.tag.name
                                  ),
                                  function(value, btnIndex) {
                                    return _c(
                                      "option",
                                      {
                                        key: 100 + btnIndex,
                                        domProps: { value: value }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(value) +
                                            "\n                  "
                                        )
                                      ]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "px-4 mt-2 mb-3" },
                          [
                            _c("label", [_vm._v(_vm._s(_vm.$t("__variable")))]),
                            item.userVars.length == 0
                              ? _c("div", { staticClass: "ml-1" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "text-info m-1",
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addButtonVar(
                                            fbElemIndex,
                                            btnIndex
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-plus text-info"
                                      })
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._l(item.userVars, function(varItem, varIndex) {
                              return _c("div", { key: varIndex }, [
                                _c("div", { staticClass: "form-row mt-2" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: varItem.variable,
                                          expression: "varItem.variable"
                                        }
                                      ],
                                      staticClass: "col-3 form-control ml-2",
                                      on: {
                                        change: function($event) {
                                          var $$selectedVal = Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function(o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function(o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                          _vm.$set(
                                            varItem,
                                            "variable",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            disabled: "",
                                            selected: ""
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  "__UserVariableSelectOptional"
                                                )
                                              ) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _vm._l(_vm.allVariables, function(
                                        value,
                                        btnIndex
                                      ) {
                                        return _c(
                                          "option",
                                          {
                                            key: 100 + btnIndex,
                                            domProps: { value: value }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(value) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: varItem.value,
                                        expression: "varItem.value"
                                      }
                                    ],
                                    staticClass: "col-3 form-control ml-2",
                                    attrs: { type: "text" },
                                    domProps: { value: varItem.value },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          varItem,
                                          "value",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  }),
                                  _c("div", { staticClass: "ml-1" }, [
                                    item.userVars.length > 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-danger m-1",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteButtonVar(
                                                  fbElemIndex,
                                                  btnIndex,
                                                  varIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-minus text-danger"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _c("div", { staticClass: "ml-1" }, [
                                    varIndex == item.userVars.length - 1
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "text-info m-1",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.addButtonVar(
                                                  fbElemIndex,
                                                  btnIndex
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-plus text-info"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ])
                            })
                          ],
                          2
                        ),
                        _c("div", { staticClass: "form-row px-4 mt-3" }, [
                          _c("div", { staticClass: "form-group col-12 mb-0" }, [
                            _c("label", [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v("*")
                              ]),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("__buttonTappedAction"))
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "form-group col-auto mb-0" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-auto" },
                                  [
                                    _c("label", [
                                      _vm._v(
                                        _vm._s(_vm.$t("__jumpToSpecificUrl"))
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row align-items-center"
                                      },
                                      [
                                        _c("md-radio", {
                                          staticClass: "ml-3",
                                          attrs: {
                                            type: "radio",
                                            value: "uri"
                                          },
                                          model: {
                                            value: item.action.nodetype,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item.action,
                                                "nodetype",
                                                $$v
                                              )
                                            },
                                            expression: "item.action.nodetype"
                                          }
                                        }),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: item.action.uri,
                                              expression: "item.action.uri"
                                            }
                                          ],
                                          staticClass:
                                            "form-control col-10 ml-0",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "__urlPlaceHolder"
                                            ),
                                            id: "uri-" + btnIndex
                                          },
                                          domProps: { value: item.action.uri },
                                          on: {
                                            focus: function($event) {
                                              return _vm.buttonActionOnchanged(
                                                "uri",
                                                fbElemIndex,
                                                btnIndex
                                              )
                                            },
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                item.action,
                                                "uri",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-row col-auto" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-6" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("__jumpToSpecificScenario")
                                            )
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-row align-items-center"
                                          },
                                          [
                                            _c("md-radio", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                type: "radio",
                                                value: "snode"
                                              },
                                              model: {
                                                value: item.action.nodetype,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item.action,
                                                    "nodetype",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.action.nodetype"
                                              }
                                            }),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.action.data,
                                                    expression:
                                                      "item.action.data"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-9",
                                                attrs: { id: "snode" },
                                                on: {
                                                  focus: function($event) {
                                                    return _vm.buttonActionOnchanged(
                                                      "snode",
                                                      fbElemIndex,
                                                      btnIndex
                                                    )
                                                  },
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      item.action,
                                                      "data",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "",
                                                      disabled: "",
                                                      selected: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "__NextScenariooptional"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(
                                                  _vm.allScenario,
                                                  function(scenario, btnIndex) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: 100 + btnIndex,
                                                        domProps: {
                                                          value:
                                                            scenario.scenario
                                                              .scenarioId
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                            " +
                                                            _vm._s(
                                                              scenario.scenario
                                                                .scenarioName
                                                            ) +
                                                            "\n                          "
                                                        )
                                                      ]
                                                    )
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-6" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("__jumpToSpecificNode")
                                            )
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "form-row align-items-center"
                                          },
                                          [
                                            _c("md-radio", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                type: "radio",
                                                value: "nnode"
                                              },
                                              model: {
                                                value: item.action.nodetype,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item.action,
                                                    "nodetype",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.action.nodetype"
                                              }
                                            }),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.action.data,
                                                    expression:
                                                      "item.action.data"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-9",
                                                attrs: { id: "nnode" },
                                                on: {
                                                  focus: function($event) {
                                                    return _vm.buttonActionOnchanged(
                                                      "nnode",
                                                      fbElemIndex,
                                                      btnIndex
                                                    )
                                                  },
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      item.action,
                                                      "data",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "",
                                                      disabled: "",
                                                      selected: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                            " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "__NextNodeoptional"
                                                          )
                                                        ) +
                                                        "\n                          "
                                                    )
                                                  ]
                                                ),
                                                _vm._l(_vm.allNodes, function(
                                                  node,
                                                  idx
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: idx,
                                                      domProps: {
                                                        value: node.nodeId
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(node.title) +
                                                          " " +
                                                          _vm._s(
                                                            node.subtitle
                                                          ) +
                                                          "\n                          "
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  element.buttons.length < 3
                    ? _c(
                        "div",
                        {
                          staticClass: "row pt-4 add-btn-input cursor-pointer",
                          on: {
                            click: function($event) {
                              return _vm.addNewButton(fbElemIndex)
                            }
                          }
                        },
                        [_vm._m(0, true)]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }