var render = function() {
  var _obj, _obj$1, _obj$2
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass: "btn",
      class: [
        { "btn-round": _vm.round },
        { "btn-block": _vm.block },
        { "btn-just-icon": _vm.icon },
        ((_obj = {}),
        (_obj["btn-" + _vm.type] = _vm.type && !_vm.outline),
        _obj),
        ((_obj$1 = {}),
        (_obj$1["btn-outline-" + _vm.type] = _vm.type && _vm.outline),
        _obj$1),
        ((_obj$2 = {}), (_obj$2["btn-" + _vm.size] = _vm.size), _obj$2),
        { "btn-link": _vm.simple }
      ],
      attrs: { type: _vm.nativeType, disabled: _vm.disabled || _vm.loading }
    },
    [
      _vm._t("loading", [
        _vm.loading
          ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
          : _vm._e()
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }