var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card",
    [
      _c("template", { slot: "header" }, [
        _vm.$slots.title || _vm.title
          ? _c(
              "h4",
              { staticClass: "card-title" },
              [
                _vm._t("title", [
                  _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
                ])
              ],
              2
            )
          : _vm._e(),
        _c(
          "p",
          { staticClass: "card-category" },
          [
            _vm._t("subTitle", [
              _vm._v("\n        " + _vm._s(_vm.subTitle) + "\n      ")
            ])
          ],
          2
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "ct-chart", attrs: { id: _vm.chartId } }),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "chart-legend" }, [_vm._t("legend")], 2),
          _c("hr"),
          _c("div", { staticClass: "stats" }, [_vm._t("footer")], 2),
          _c("div", { staticClass: "pull-right" })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }