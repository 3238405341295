var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm.$slots.image
        ? _c("div", { staticClass: "card-image" }, [_vm._t("image")], 2)
        : _vm._e(),
      _vm.$slots.header || _vm.title
        ? _c(
            "div",
            { staticClass: "card-header" },
            [
              _vm._t("header", [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ]),
                _vm.subTitle
                  ? _c("p", { staticClass: "card-category" }, [
                      _vm._v(_vm._s(_vm.subTitle))
                    ])
                  : _vm._e()
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.$slots.default
        ? _c("div", { staticClass: "card-body" }, [_vm._t("default")], 2)
        : _vm._e(),
      _vm._t("raw-content"),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "card-footer" },
            [_c("hr"), _vm._t("footer")],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }