
export const ImageMapActions = {

    getActions: function (layout, areaOptions) {
        var actions;
        switch (layout) {
            case "A":
                actions = this.action_layoutA(areaOptions);
                break
            case "B":
                actions = this.action_layoutB(areaOptions);
                break
            case "C":
                actions = this.action_layoutC(areaOptions);
                break
            case "D":
                actions = this.action_layoutD(areaOptions);
                break
            default:
                actions = [];
        }
        for (let i=0; i < actions.length; i++) {
            if (actions[i].type == "message") {
                actions[i]["text"] = areaOptions[i].text;
                areaOptions[i]["url"] = "";
            } else if (actions[i].type == "uri") {
                actions[i]["linkUri"] = areaOptions[i].url;
                areaOptions[i]["text"] = "";
            }
        }
        return actions;
    },

    action_layoutA: function (areaOptions) {
        return [
            {
                type: areaOptions[0].type,
                area: {
                    x: 0,
                    y: 0,
                    width: 1040,
                    height: 1040
                },
            }
        ];
    },

    action_layoutB: function (areaOptions) {
        return [
            {
                type: areaOptions[0].type,
                area: {
                    x: 0,
                    y: 0,
                    width: 520,
                    height: 1040
                },
            },
            {
                type: areaOptions[1].type,
                area: {
                    x: 520,
                    y: 0,
                    width: 520,
                    height: 1040
                },
            }
        ]
    },

    action_layoutC: function (areaOptions) {
        return [
            {
                type: areaOptions[0].type,
                area: {
                    x: 0,
                    y: 0,
                    width: 1040,
                    height: 520
                },
            },
            {
                type: areaOptions[1].type,
                area: {
                    x: 0,
                    y: 520,
                    width: 1040,
                    height: 520
                },
            }
        ]
    },

    action_layoutD: function (areaOptions) {
        return [
            {
                type: areaOptions[0].type,
                area: {
                    x: 0,
                    y: 0,
                    width: 1040,
                    height: 520
                },
            },
            {
                type: areaOptions[1].type,
                area: {
                    x: 0,
                    y: 520,
                    width: 520,
                    height: 520
                },
            },
            {
                type: areaOptions[2].type,
                area: {
                    x: 520,
                    y: 520,
                    width: 520,
                    height: 520
                },
            }
        ]
    },

}