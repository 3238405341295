var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table", class: _vm.tableClass }, [
    _c(
      "thead",
      [
        _vm._t(
          "columns",
          _vm._l(_vm.columns, function(column) {
            return _c("th", { key: column }, [_vm._v(_vm._s(column))])
          })
        )
      ],
      2
    ),
    _c(
      "tbody",
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "tr",
          { key: index },
          [
            _vm._t(
              "default",
              _vm._l(_vm.columns, function(column, index) {
                return _vm.hasValue(item, column)
                  ? _c("td", { key: index }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.itemValue(item, column)) +
                          "\n      "
                      )
                    ])
                  : _vm._e()
              }),
              { row: item }
            )
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }