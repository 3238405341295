var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("card", { staticClass: "main-content" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "ml-auto py-2 pr-4" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search"
            }
          ],
          staticClass: "search-box",
          attrs: { id: "search-box-plan", type: "text", name: "q" },
          domProps: { value: _vm.search },
          on: {
            keyup: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.searchUser($event)
            },
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.search = $event.target.value
            }
          }
        }),
        _c(
          "label",
          { attrs: { for: "search-box-plan" }, on: { click: _vm.searchUser } },
          [_c("span", { staticClass: "fas fa-search search-icon" })]
        )
      ])
    ]),
    _c("div", { staticClass: "row mt-4 mb-1" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("span", [_vm._v(" " + _vm._s(_vm.$t("__Searchusersnow")))]),
        _c("span", [_vm._v(": " + _vm._s(_vm.allUsers.length))])
      ]),
      _c("div", { staticClass: "ml-auto mr-4" }, [
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.$router.push("/segment")
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("__Userclassification")))]
        ),
        _c("span", { staticClass: "px-2" }, [_vm._v("/")]),
        _c(
          "a",
          {
            staticStyle: { color: "#009688" },
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.$router.push("/segment/userList")
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("__Userlist")))]
        )
      ])
    ]),
    _vm.isLoading
      ? _c("div", { staticClass: "text-center" }, [
          _c("div", { staticClass: "spinner-border" }, [
            _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
          ])
        ])
      : _vm._e(),
    !_vm.isLoading
      ? _c(
          "div",
          { staticClass: "table-responsive" },
          [
            _c("userList-table", {
              attrs: {
                type: "hover",
                data: _vm.table.data,
                columns: _vm.table.columns,
                page: _vm.page,
                rowPerPage: _vm.rowPerPage
              }
            }),
            _c("pagination", {
              attrs: {
                "per-page": _vm.rowPerPage,
                records: _vm.filteredData.length,
                options: {
                  texts: {
                    count: this.$t("__paginationText")
                  }
                }
              },
              on: { paginate: _vm.callback },
              model: {
                value: _vm.page,
                callback: function($$v) {
                  _vm.page = $$v
                },
                expression: "page"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "ml-auto mr-4" }, [
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.getFollowers()
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.$t("__getFollowers")))]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }