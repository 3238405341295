var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table", class: _vm.tableClass },
    [
      _c("thead", { staticClass: "thead-dark" }, [
        _c("th", [_vm._v("#")]),
        _c(
          "th",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.onClickSegmentNameColumnHeader }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(this.$t("__CategoryName")) +
                " " +
                _vm._s(_vm.getSortOrderLabel(_vm.segmentNameColumnSortState)) +
                "\n    "
            )
          ]
        ),
        _c("th", [_vm._v(_vm._s(this.$t("__Classificationrules")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Numberofpeopleincategory")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Sendconversation")))]),
        _c("th")
      ]),
      _c(
        "tbody",
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm._t(
                "default",
                [
                  _c("td", [_vm._v(_vm._s(index + 1))]),
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _c("td", [
                    item.conditions.length > 0
                      ? _c(
                          "div",
                          _vm._l(item.conditions, function(
                            condition,
                            condition_index
                          ) {
                            return _c(
                              "div",
                              {
                                key: "tag_" + condition_index,
                                staticClass: "mb-1"
                              },
                              _vm._l(condition.multi_condition, function(
                                subItem,
                                subIndex
                              ) {
                                return _c(
                                  "ul",
                                  {
                                    key: "cond_" + subIndex,
                                    staticClass:
                                      "list-group list-group-horizontal"
                                  },
                                  [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "list-group-item",
                                        staticStyle: { width: "150px" }
                                      },
                                      [
                                        subItem.source == "uservariable"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("__variable"))
                                              )
                                            ])
                                          : _vm._e(),
                                        subItem.source == "tag"
                                          ? _c("span", [
                                              _vm._v(_vm._s(_vm.$t("__label")))
                                            ])
                                          : _vm._e(),
                                        subItem.source == "followtime"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("__FollowAccountTime")
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        subItem.source == "cvkey"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("__CvParam"))
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "list-group-item",
                                        staticStyle: { width: "150px" }
                                      },
                                      [
                                        subItem.source == "uservariable"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  subItem.uservariable.name
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        subItem.source == "tag"
                                          ? _c("span", [
                                              _vm._v(_vm._s(subItem.tag.name))
                                            ])
                                          : _vm._e(),
                                        subItem.source == "cvkey"
                                          ? _c("span", [
                                              _vm._v(_vm._s(subItem.value))
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "list-group-item",
                                        staticStyle: { width: "100px" }
                                      },
                                      [
                                        subItem.expression == "exactly_match"
                                          ? _c("span", [_vm._v("完全一致")])
                                          : _vm._e(),
                                        subItem.expression == "fuzzy_match"
                                          ? _c("span", [_vm._v("部分一致")])
                                          : _vm._e(),
                                        subItem.expression == "has_value"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("__segmentcondition")
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        subItem.expression == "no_value"
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("__segmentnocondition")
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        subItem.expression == "num_range"
                                          ? _c("span", [_vm._v("數值範圍")])
                                          : _vm._e(),
                                        subItem.expression == "num_gt"
                                          ? _c("span", [_vm._v("大於數值")])
                                          : _vm._e(),
                                        subItem.expression == "num_lt"
                                          ? _c("span", [_vm._v("小於數值")])
                                          : _vm._e(),
                                        subItem.expression == "date_range"
                                          ? _c("span", [_vm._v("日期區間")])
                                          : _vm._e()
                                      ]
                                    ),
                                    (subItem.expression != "has_value") &
                                    (subItem.expression != "no_value")
                                      ? _c(
                                          "li",
                                          {
                                            staticClass: "list-group-item",
                                            staticStyle: { width: "240px" }
                                          },
                                          [
                                            subItem.expression ==
                                            "exactly_match"
                                              ? _c("span", [
                                                  _vm._v(_vm._s(subItem.value))
                                                ])
                                              : _vm._e(),
                                            subItem.expression == "fuzzy_match"
                                              ? _c("span", [
                                                  _vm._v(_vm._s(subItem.value))
                                                ])
                                              : _vm._e(),
                                            subItem.expression == "num_gt"
                                              ? _c("span", [
                                                  _vm._v(_vm._s(subItem.value))
                                                ])
                                              : _vm._e(),
                                            subItem.expression == "num_lt"
                                              ? _c("span", [
                                                  _vm._v(_vm._s(subItem.value))
                                                ])
                                              : _vm._e(),
                                            subItem.expression == "num_range"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      subItem.range.start
                                                    ) +
                                                      " ~ " +
                                                      _vm._s(subItem.range.end)
                                                  )
                                                ])
                                              : _vm._e(),
                                            subItem.expression == "date_range"
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.toDateString(
                                                        subItem.range.start
                                                      )
                                                    ) +
                                                      " ~ " +
                                                      _vm._s(
                                                        _vm.toDateString(
                                                          subItem.range.end
                                                        )
                                                      )
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }),
                              0
                            )
                          }),
                          0
                        )
                      : item.followTime
                      ? _c("div", [
                          _c("span", { staticClass: "mr-3" }, [
                            _vm._v("Follow Date")
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.toDateString(item.followTime.start)) +
                                " ~ " +
                                _vm._s(_vm.toDateString(item.followTime.end))
                            )
                          ])
                        ])
                      : item.mode == "CVKey"
                      ? _c("div", [
                          _c("span", [
                            _vm._v("orderId: " + _vm._s(item.cvKey.orderId))
                          ])
                        ])
                      : _c("span", [_vm._v(_vm._s(_vm.$t("__Custom")))])
                  ]),
                  _c(
                    "td",
                    {
                      class: { pointer: item.users.length != 0 },
                      on: {
                        click: function($event) {
                          return _vm.showUser(item.users)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.countItem(item.users)))]
                  ),
                  _c("td", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function($event) {
                            return _vm.broadcastInSegment(item)
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "bullhorn" } })
                      ],
                      1
                    )
                  ]),
                  _c("td", [
                    _c(
                      "a",
                      {
                        staticClass: "px-2",
                        attrs: {
                          href: "javascript:void(0)",
                          "data-toggle": "modal",
                          "data-target": "#segment"
                        },
                        on: {
                          click: function($event) {
                            return _vm.editSegment(item)
                          }
                        }
                      },
                      [_c("font-awesome-icon", { attrs: { icon: "edit" } })],
                      1
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function($event) {
                            return _vm.deleteSegment(item.id)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-trash text-danger" })]
                    )
                  ])
                ],
                { row: item }
              )
            ],
            2
          )
        }),
        0
      ),
      _vm.componentKey
        ? _c("create-segment-modal", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              modalData: _vm.modalData,
              editId: _vm.editId
            }
          })
        : _vm._e(),
      _vm.pushMessageKey
        ? _c("create-push-modal", {
            key: _vm.pushMessageKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              modalData: _vm.broadcastData
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "btn-follow",
          on: {
            click: function($event) {
              return _vm.createSegment()
            }
          }
        },
        [_vm._m(0)]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticStyle: { color: "#FFF" } }, [
      _c("i", { staticClass: "fas fa-plus" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }