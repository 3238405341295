<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th style="cursor: pointer;" @click="onClickUserNameColumnHeader">
        {{this.$t('__username')}} {{ getSortOrderLabel(userNameColumnSortState) }}
      </th>
      <th>Line/Wechat/Fb ID</th>
      <th>{{this.$t('__userstatuse')}}</th>
      <th>{{this.$t('__Followtime')}}</th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ (page-1)*rowPerPage + index + 1 }}</td>
          <td>
            <img :src="item.userPicture" width="30" />
            <span class="px-2">{{ item.userName }}</span>
          </td>
          <td>{{ item.userId }}</td>
          <td v-html="accountStatus(item.accountStatus)"></td>
          <td>{{ toFrontendTimeFormat(item.followTime) }}</td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "userList-table",
  props: {
    columns: Array,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    page: Number,
    rowPerPage: Number
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    }
  },
  data() {
    return {
      // テーブルのソート状態（none, asc, desc）
      userNameColumnSortState: 'none',
    }
  },
  methods: {
    // Example: 2020-06-29 09:10
    toFrontendTimeFormat(dateTime) {
      return this.$dateTime.toFrontEndTimeFormat(dateTime);
    },
    // Status type: 0, 1
    accountStatus(status) {
      if (status === "0") {
        return '<span class="text-danger">'+this.$t('__blockade')+'</span>';
      }
      return '<span class="text-success">'+this.$t('__Active')+'</span>';
    },
    getSortOrderLabel(order) {
      if (order == 'none') return '';
      return order == 'asc' ? '▲' : '▼';
    },
    onClickUserNameColumnHeader() {
      this.userNameColumnSortState = (this.userNameColumnSortState == 'asc' ? 'desc' : 'asc');
      this.sortTableByUserNameColumn();
    },
    sortTableByUserNameColumn() {
      if (this.userNameColumnSortState == 'none') return;
      const ascFunc = (a, b) => {
        if (a.userName < b.userName) return -1;
        if (a.userName > b.userName) return 1;
        return 0;
      }
      const descFunc = (a, b) => {
        if (a.userName > b.userName) return -1;
        if (a.userName < b.userName) return 1;
        return 0;
      }
      this.data.sort(this.userNameColumnSortState == 'asc' ? ascFunc : descFunc);
    }
  }
};
</script>
<style>
</style>
