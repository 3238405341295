import CreateEditStoryModal from "./CreateEditStory.vue";
import CreateEditNodeModal from "./CreateEditNode.vue";
import CreateTagModal from "./CreateTag.vue";
import ExistTagModal from "./ExistTag.vue";

let modals = {
    CreateEditStoryModal,
    CreateEditNodeModal,
    CreateTagModal,
    ExistTagModal,
}

export default modals;

export {
    CreateEditStoryModal,
    CreateEditNodeModal,
    CreateTagModal,
    ExistTagModal,
}
