import md5 from 'js-md5';
export const Utility = {
    deepCopy: function (inObject) {
        let outObject, value, key

        if (typeof inObject !== "object" || inObject === null) {
            return inObject // Return the value if inObject is not an object
        }

        // Create an array or object to hold the values
        outObject = Array.isArray(inObject) ? [] : {}

        for (key in inObject) {
            value = inObject[key]

            // Recursively (deep) copy for nested objects, including arrays
            outObject[key] = this.deepCopy(value)
        }

        return outObject
    },
    checkFlexMsgJson(json) {
        var err = null;
        if (json.footer && json.footer.contents && Array.isArray(json.footer.contents)) {
            json.footer.contents.forEach(content => {
                if (content.type && content.type === "spacer") {
                    err = "Spacer is no longer supported";
                }
            })
        }
        return err;
    },
    // Example: 1186c182-b9c9-11ea-985b-8e3943a729dd
    generateNewId(time, shift) {
      let id =
        Math.random().toString(36).slice(2) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        "-" +
        Math.random().toString(36).substr(2, 4) +
        md5(time).substr(2, 8) + shift;

      return id;
    },

    toDateString: function (datetime) {
        const date = new Date(datetime);
        return date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate();
    },
};