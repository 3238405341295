var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("card", { staticClass: "main-content" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "ml-auto py-2 pr-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search"
                }
              ],
              staticClass: "search-box",
              attrs: { id: "search-box", type: "text", name: "q" },
              domProps: { value: _vm.search },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.search = $event.target.value
                }
              }
            }),
            _c("label", { attrs: { for: "search-box" } }, [
              _c("span", { staticClass: "fas fa-search search-icon" })
            ])
          ])
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c("tagList-table", {
                attrs: {
                  type: "hover table-bordered",
                  adminId: _vm.adminId,
                  jwtToken: _vm.jwtToken,
                  accountId: _vm.accountId,
                  data: _vm.table.data,
                  columns: _vm.table.columns
                },
                on: { editTag: _vm.editTag, deleteTag: _vm.deleteTag }
              }),
              _c("pagination", {
                attrs: {
                  "per-page": _vm.rowPerPage,
                  records: _vm.allTag.length,
                  options: {
                    texts: {
                      count: this.$t("__paginationText")
                    }
                  }
                },
                on: { paginate: _vm.callback },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "btn-follow" }, [
          _c(
            "a",
            {
              staticStyle: { color: "#fff" },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  return _vm.showCreateTagModal()
                }
              }
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          )
        ])
      ]),
      _c("create-tag-modal", {
        ref: "createTagModal",
        attrs: { newTagData: _vm.modalData },
        on: { newTag: _vm.newTag }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }