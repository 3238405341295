var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table", class: _vm.tableClass }, [
    _c("thead", { staticClass: "thead-dark" }, [
      _c("th", [_vm._v("#")]),
      _c(
        "th",
        {
          staticStyle: { cursor: "pointer" },
          on: { click: _vm.onClickUserNameColumnHeader }
        },
        [
          _vm._v(
            "\n      " +
              _vm._s(this.$t("__username")) +
              " " +
              _vm._s(_vm.getSortOrderLabel(_vm.userNameColumnSortState)) +
              "\n    "
          )
        ]
      ),
      _c("th", [_vm._v("Line/Wechat/Fb ID")]),
      _c("th", [_vm._v(_vm._s(this.$t("__userstatuse")))]),
      _c("th", [_vm._v(_vm._s(this.$t("__Followtime")))])
    ]),
    _c(
      "tbody",
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "tr",
          { key: index },
          [
            _vm._t(
              "default",
              [
                _c("td", [
                  _vm._v(_vm._s((_vm.page - 1) * _vm.rowPerPage + index + 1))
                ]),
                _c("td", [
                  _c("img", { attrs: { src: item.userPicture, width: "30" } }),
                  _c("span", { staticClass: "px-2" }, [
                    _vm._v(_vm._s(item.userName))
                  ])
                ]),
                _c("td", [_vm._v(_vm._s(item.userId))]),
                _c("td", {
                  domProps: {
                    innerHTML: _vm._s(_vm.accountStatus(item.accountStatus))
                  }
                }),
                _c("td", [
                  _vm._v(_vm._s(_vm.toFrontendTimeFormat(item.followTime)))
                ])
              ],
              { row: item }
            )
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }