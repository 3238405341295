<template>
  <div>
    <table class="table" :class="tableClass">
      <thead class="thead-dark">
        <th><input type="checkbox" v-model="chkAll" v-on:change="ChkAllEvent"></th>
        <th>#</th>
        <th style="cursor: pointer" @click="onClickBroadcastNameColumnHeader">
          {{ this.$t("__SendID") }}
          {{ getSortOrderLabel(broadcastNameColumnSortState) }}
        </th>
        <th>{{ this.$t("__Conversationname") }}</th>
        <th>{{ this.$t("__Pushtarget") }}</th>
        <th>{{ this.$t("__sendstatus") }}</th>
        <th>{{ this.$t("__Scheduleddeliverydate") }}</th>
        <th>{{ this.$t("__Editdateandtime") }}</th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <slot :row="item">
            <td><input type="checkbox" v-model="item.checked"></td>
            <td>{{ index + 1 }}</td>
            <td>{{ item.broadcastName }}</td>
            <td>{{ item.broadcastScenarioName }}</td>
            <td @click="showUser(item.broadcastUsers)" class="pointer">
              {{ item.broadcastUsers.length }}
            </td>
            <td
              v-html="
                broadcastStatus(item.broadcastStatus, item.success, item.failed)
              "
            ></td>
            <td>{{ toFrontendTimeFormat(item.broadcastTime) }}</td>
            <td>{{ toFrontendTimeFormat(item.updateTime) }}</td>
            <td>
              <template>
                <a
                  href="javascript:void(0)"
                  class="px-2"
                  @click="editBroadcastPlan(item)"
                >
                  <i class="fas fa-edit"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  @click="deleteBroadcastPlan([item.broadcastId])"
                >
                  <i class="fas fa-trash text-danger mx-1"></i>
                </a>
              </template>
            </td>
          </slot>
        </tr>
      </tbody>
      <!-- add -->
      <div id="add-schedule" class="btn-follow" @click="createBroadcast()">
        <a style="color: #fff">
          <i class="fas fa-plus"></i>
        </a>
      </div>
      <create-push-modal
        :adminId="adminId"
        :jwtToken="jwtToken"
        :accountId="accountId"
        v-bind:modalData="modalData"
        :editId="editId"
        :key="componentKey"
        v-if="componentKey"
      ></create-push-modal>
    </table>
    <button type="button" class="btn btn-outline-danger btn-sm float-right" @click="multiDeleteBroadcastPlan()">{{ this.$t("__MultiDelete") }}</button>
  </div>
</template>
<script>
import { CreatePushModal } from "../Modals/index";
import { apiFunction } from "../Axios/functions";
import Button from '../../../components/Button.vue';

export default {
  name: "push-message-table",
  components: { CreatePushModal, Button },
  props: {
    columns: Array,
    adminId: String,
    jwtToken: String,
    accountId: String,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped",
    },
    currentNumber: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      chkAll: false,
      broadcastId: "",
      modalData: {
        broadcastName: "",
        modalBtn: this.$t("__edit"),
        modalSuccessMsg: "",
      },
      editId: "",
      componentKey: 0,
      // テーブルのソート状態（none, asc, desc）
      broadcastNameColumnSortState: "none",
    };
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },

    // Init new name
    // Example: PM00001
    getNewBroadcastName() {
      let totalBroadcast = this.currentNumber;
      let defaultName = (Number(totalBroadcast) + 1).toString();
      return "PM" + defaultName.padStart(5, "0");
    },
    // Status type: done, issue, pending
    broadcastStatus(status, success, failed) {
      if (status == "done") {
        return `<span class="text-success">${this.$t("__statusDone")}</span>`;
      }
      if (status == "issue") {
        if (!success && !failed) {
          return `<span class="text-warning">${this.$t(
            "__statusIssue"
          )}</span>`;
        } else {
          return `<span class="text-warning">${this.$t(
            "__statusIssue"
          )}(${this.$t("__statusDone")}:${success}/${this.$t(
            "__statusIssue"
          )}:${failed})</span>`;
        }
      }
      if (status == "pending") {
        return `<span class="text-danger">${this.$t("__statusPending")}</span>`;
      }
      return "";
    },
    // Example: 2020-06-30 13:24
    toFrontendTimeFormat(dateTime) {
      return this.$dateTime.toFrontEndTimeFormat(dateTime);
    },
    // Show users name when click number of people
    showUser(users) {
      let arr = [];
      users.forEach((user) => {
        arr.push(user.userName);
      });
      this.$alert(arr);
    },
    // Create broadcast plan
    createBroadcast() {
      this.forceRerender();
      this.modalData.broadcastName = this.getNewBroadcastName();
      this.editId = "";
      this.modalData.broadcastScenarioId = "";
      this.modalData.broadcastScenarioName = "";
      this.modalData.broadcastTime = null;
      this.modalData.broadcastUsers = [];
      this.modalData.segmentNames = [];
      this.modalData.modalBtn = this.$t("__setup");
      this.modalData.modalSuccessMsg = this.$t(
        "__Theappointmentschedulehasbeensuccessfullycreated"
      );
    },
    // Edit broadcast plan
    editBroadcastPlan(broadcast) {
      this.forceRerender();
      this.editId = broadcast.broadcastId;
      this.modalData.broadcastName = broadcast.broadcastName;
      this.modalData.broadcastScenarioId = broadcast.broadcastScenarioId;
      this.modalData.broadcastScenarioName = broadcast.broadcastScenarioName;
      this.modalData.broadcastTime = broadcast.broadcastTime;
      this.modalData.broadcastUsers = broadcast.broadcastUsers;
      this.modalData.segmentNames = broadcast.segmentNames;
      this.modalData.modalBtn = this.$t("__edit");
      this.modalData.modalSuccessMsg = this.$t("__Editsuccessfullyaccessed");
    },
    // Delete broadcast plan
    deleteBroadcastPlan(delBdCastArr) {
      this.$fire({
        title: this.$t("__Areyousureyouwanttodeleteit"),
        text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("__YesIwanttodelete"),
        cancelButtonText: this.$t("__cancel"),
      }).then((result) => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            broadcastIds: delBdCastArr,
          };

          apiFunction.deleteBroadcast(formData, this.jwtToken).then((data) => {
            if (data) {
              this.$fire({
                title: this.$t("__successfullydeleted"),
                text: this.$t("__Yourdatahasbeensuccessfullydeleted"),
                type: "success",
                timer: 1500,
              }).then((e) => {
                this.$router.go();
              });
            } else {
              this.$fire({
                title: this.$t("__failedtodelete"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            }
          });
        }
      });
    },
    multiDeleteBroadcastPlan() {
      let delBdCast = [];
      this.data.forEach(item => {
        if (item["checked"]) {
          delBdCast.push(item.broadcastId);
        }
      });
      if (delBdCast.length > 0) {
        this.deleteBroadcastPlan(delBdCast);
      }
    },
    getSortOrderLabel(order) {
      if (order == "none") return "";
      return order == "asc" ? "▲" : "▼";
    },
    onClickBroadcastNameColumnHeader() {
      this.broadcastNameColumnSortState =
        this.broadcastNameColumnSortState == "asc" ? "desc" : "asc";
      this.sortTableByBroadcastNameColumn();
    },
    sortTableByBroadcastNameColumn() {
      if (this.broadcastNameColumnSortState == "none") return;
      const ascFunc = (a, b) => {
        if (a.broadcastName < b.broadcastName) return -1;
        if (a.broadcastName > b.broadcastName) return 1;
        return 0;
      };
      const descFunc = (a, b) => {
        if (a.broadcastName > b.broadcastName) return -1;
        if (a.broadcastName < b.broadcastName) return 1;
        return 0;
      };
      this.data.sort(
        this.broadcastNameColumnSortState == "asc" ? ascFunc : descFunc
      );
    },
    ChkAllEvent() {
      if (this.chkAll) {
        this.data.forEach(item => {
          item["checked"] = true;
        })
      } else {
        this.data.forEach(item => {
          item["checked"] = false;
        })
      }
    }
  },
};
</script>
<style>
</style>
