<template>
  <card class="main-content">
    <!-- search -->
    <div class="row">
      <div class="ml-auto py-2 pr-4">
        <input
          id="search-box-plan"
          type="text"
          class="search-box"
          name="q"
          v-model="search"
          v-on:keyup.enter="searchUser"
        />
        <label for="search-box-plan" v-on:click="searchUser">
          <span class="fas fa-search search-icon"></span>
        </label>
      </div>
    </div>
    <!-- search -->
    <!-- content start -->
    <div class="row mt-4 mb-1">
      <div class="col-6">
        <span> {{ $t("__Searchusersnow") }}</span>
        <span>: {{ allUsers.length }}</span>
      </div>
      <div class="ml-auto mr-4">
        <a href="javascript:void(0)" @click="$router.push('/segment')">
          {{ $t("__Userclassification") }}</a
        >
        <span class="px-2">/</span>
        <a
          href="javascript:void(0)"
          @click="$router.push('/segment/userList')"
          style="color: #009688"
        >
          {{ $t("__Userlist") }}</a
        >
      </div>
    </div>
    <!-- Loading spinner -->
    <div v-if="isLoading" class="text-center">
      <div class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" class="table-responsive">
      <userList-table
        type="hover"
        :data="table.data"
        :columns="table.columns"
        :page="page"
        :rowPerPage="rowPerPage"
      ></userList-table>
      <!-- content end -->
      <pagination
        v-model="page"
        :per-page="rowPerPage"
        :records="filteredData.length"
        :options="{
          texts: {
            count: this.$t('__paginationText'),
          },
        }"
        @paginate="callback"
      ></pagination>
    </div>
    <div class="row">
      <div class="ml-auto mr-4">
        <a href="javascript:void(0)" @click="getFollowers()">
          {{ $t("__getFollowers") }}</a
        >
      </div>
    </div>
  </card>
</template>
<script>
import { UserListTable } from "./Components";
import { apiFunction } from "./Axios/functions";

export default {
  components: {
    UserListTable,
  },
  data() {
    const tableData = [];
    return {
      isLoading: false,
      search: "",
      adminId: "",
      jwtToken: "",
      accountId: "",
      page: 1,
      rowPerPage: 20,
      table: {
        data: [...tableData],
      },
      allUsers: [],
      filteredData: [],
    };
  },
  watch: {
    // search: {
    //   handler: function(val, oldval) {
    //     let reg = new RegExp(val);
    //     let searchResult = [];
    //     $.each(this.allUsers, function(i, userList) {
    //       if (
    //         reg.test(userList.userName) == true ||
    //         reg.test(userList.userId) == true
    //       ) {
    //         searchResult.push(userList);
    //       }
    //     });
    //     this.filteredData = searchResult;
    //     this.callback(1);
    //   }
    // }
    // search:{
    //   handler:function(val, oldval){
    //     let reg = new RegExp(val);
    //     let searchResult = [];
    //     let formData = {
    //       adminId: this.adminId,
    //       accountId: this.accountId,
    //       keywords: val
    //     };
    //     apiFunction.getUserList(formData, this.jwtToken).then(data => {
    //       if (data.error) {
    //         this.$fire({
    //           title: this.$t('__authExpired'),
    //           text: this.$t('__pleaseLogin'),
    //           type: "error"
    //         }).then(() => {
    //           this.$Amplify.Auth.signOut();
    //           localStorage.clear();
    //           this.$router.push("/login");
    //         });
    //       } else {
    //         let userArr = [];
    //         let sortedUser = this.sortUser(data.data.Result.data);
    //         this.removeDupUser(sortedUser).forEach(user => {
    //           userArr.push(user);
    //         });
    //         this.allUsers = this.sortByUpdateTime(userArr);
    //         this.filteredData = this.allUsers;
    //         // Init pagination
    //         this.callback(1);
    //       }
    //     });
    //   }
    // }
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
        this.searchUser();
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }

      let formData = { adminId: this.adminId, accountId: this.accountId };
      this.isLoading = true;
      apiFunction.getUserList(formData, this.jwtToken).then((data) => {
        this.isLoading = false;
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let userArr = [];
          let sortedUser = this.sortUser(data.data.Result.data);
          this.removeDupUser(sortedUser).forEach((user) => {
            userArr.push(user);
          });
          this.allUsers = this.sortByUpdateTime(userArr);
          this.filteredData = this.allUsers;
          // Init pagination
          this.callback(1);
        }
      });
    },
    // Search users
    async searchUser() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        keywords: this.search,
      };
      apiFunction.getUserList(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          let userArr = [];
          let sortedUser = this.sortUser(data.data.Result.data);
          this.removeDupUser(sortedUser).forEach((user) => {
            userArr.push(user);
          });
          this.allUsers = this.sortByUpdateTime(userArr);
          this.filteredData = this.allUsers;
          // Init pagination
          this.callback(1);
        }
      });
    },
    // Pagination
    callback(pageNum) {
      this.page = pageNum;
      let arr = this.filteredData.slice(
        this.rowPerPage * (pageNum - 1),
        this.rowPerPage * pageNum
      );
      this.table.data = arr;
    },
    // Remove duplicate by user Id
    removeDupUser(arr) {
      const filteredArr = arr.reduce((acc, current) => {
        const x = acc.find((item) => item.userId === current.userId);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return filteredArr;
    },
    // AccountStatus 1 record to the front
    sortUser(array) {
      array.sort(function (a, b) {
        return a.accountStatus < b.accountStatus ? 1 : -1;
      });
      return array;
    },
    // Sort table row by update time
    sortByUpdateTime(array) {
      array.sort(function (a, b) {
        var dateA = new Date(a.followTime),
          dateB = new Date(b.followTime);
        return dateA < dateB ? 1 : -1;
      });
      return array;
    },
    getFollowers() {
      this.$fire({
        title: this.$t("__getFollowers"),
        text: this.$t("__getFollowersConfirmMessage"),
        showCancelButton: true,
        confirmButtonText: this.$t("__getFollowersConfirm"),
        cancelButtonText: this.$t("__getFollowersCancel"),
      }).then((res) => {
        if (res.value) {
          const formData = { adminId: this.adminId, accountId: this.accountId };
          apiFunction
            .getFollowers(formData, this.jwtToken)
            .then((data) => {
              if (data && data.status == 200) {
                this.$fire({
                  title: this.$t("__getFollowers"),
                  text: this.$t("__updateFollowers"),
                  type: "success",
                  timer: 2500,
                });
              } else {
                this.$fire({
                  title: this.$t("__getFollowers"),
                  text: this.$t("__oopsSomethingwentwrong"),
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.$fire({
                title: this.$t("__getFollowers"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>
<style>
</style>
