import CreateAccount from "./CreateAccount.vue";

let modals = {
  CreateAccount
}

export default modals;

export {
  CreateAccount
}
