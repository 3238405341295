var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "table", class: _vm.tableClass },
    [
      _c("thead", { staticClass: "thead-dark" }, [
        _c(
          "th",
          {
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.onClickScenarioNameColumnHeader }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(this.$t("__Conversationname")) +
                " " +
                _vm._s(_vm.getSortOrderLabel(_vm.scenarioNameColumnSortState)) +
                "\n    "
            )
          ]
        ),
        _c("th", [_vm._v(_vm._s(this.$t("__Triggerpoint")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Numberofnodes")))]),
        _c("th", [_vm._v(_vm._s(this.$t("__Editdateandtime")))]),
        _c("th")
      ]),
      _c(
        "tbody",
        _vm._l(_vm.data, function(item, index) {
          return _c(
            "tr",
            { key: index },
            [
              _vm._t(
                "default",
                [
                  _c("td", [_vm._v(_vm._s(item.scenario.scenarioName))]),
                  _c("td", {
                    class: { pointer: item.scenario.trigger == "keyword" },
                    domProps: {
                      innerHTML: _vm._s(_vm.triggerValue(item.scenario.trigger))
                    },
                    on: {
                      click: function($event) {
                        return _vm.showKeyords(
                          item.scenario.trigger,
                          item.scenario.keywords
                        )
                      }
                    }
                  }),
                  _c("td", [_vm._v(_vm._s(item.nodes.length))]),
                  _c("td", [
                    _vm._v(_vm._s(_vm.toFrontendTimeFormat(item.updateTime)))
                  ]),
                  _c(
                    "td",
                    [
                      _c(
                        "a",
                        {
                          staticClass: "editStoryIcon",
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.editStory(item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-edit mx-1" })]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "nodes",
                              params: {
                                id: item.scenario.scenarioId,
                                adminId: _vm.adminId,
                                jwtToken: _vm.jwtToken,
                                accountId: _vm.accountId
                              }
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-comment-dots mx-1" })]
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.copyScenario(item)
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-copy mx-1" })]
                      ),
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function($event) {
                              return _vm.deleteScenario(
                                item.scenario.scenarioId
                              )
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-trash text-danger mx-1"
                          })
                        ]
                      )
                    ],
                    1
                  )
                ],
                { row: item }
              )
            ],
            2
          )
        }),
        0
      ),
      _vm.componentKey
        ? _c("create-edit-story-modal", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              allScenario: _vm.allScenario,
              modalData: _vm.modalData,
              existTrigger: _vm.trigger,
              editId: _vm.editId
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "btn-follow" }, [
        _c(
          "a",
          {
            staticStyle: { color: "#fff" },
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.createStory()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-plus" })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }