var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("h1", [_vm._v("Admin Login")]),
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.login($event)
          }
        }
      },
      [
        _c("label", [_vm._v(_vm._s(this.$t("__userName")))]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.userName,
              expression: "userName"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "text", required: "" },
          domProps: { value: _vm.userName },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.userName = $event.target.value
            }
          }
        }),
        _c("br"),
        _c("label", [_vm._v(_vm._s(this.$t("__password")))]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          staticClass: "form-control",
          attrs: { type: "password", required: "" },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _c("br"),
        _c("button", { attrs: { type: "submit" } }, [
          _vm._v(_vm._s(this.$t("__loginButton")))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }