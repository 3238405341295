var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-variable-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-title text-bold" }, [
                _vm._v(_vm._s(_vm.$t("__AddVariable")))
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-2",
                    attrs: { "data-translate": "_selectColor.edit" }
                  },
                  [_vm._v("\n            " + _vm._s(_vm.$t("__Variablename")))]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newVariable,
                        expression: "newVariable"
                      }
                    ],
                    staticClass: "form-control px-4 mx-3",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("__Variablename")
                    },
                    domProps: { value: _vm.newVariable },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.newVariable = $event.target.value
                      }
                    }
                  })
                ])
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.createVariable()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("__Createnewvariable")) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }