var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-edit-rich-menu-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal modal-dialog-centered",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              !_vm.editMode
                ? _c("p", { staticClass: "modal-title text-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("__AddRichMenu")) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm.editMode
                ? _c("p", { staticClass: "modal-title text-bold" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("__EditRichMenu")) +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "div",
                {
                  staticClass: "container-fluid",
                  staticStyle: { height: "60vh", overflow: "auto" }
                },
                [
                  _c("div", [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(
                      " " + _vm._s(_vm.$t("__requiredItem")) + "\n          "
                    )
                  ]),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _vm._v(_vm._s(_vm.$t("__RichMenuname")))
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formInput.name,
                          expression: "formInput.name"
                        }
                      ],
                      staticClass: "form-control px-4 mx-3",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("__RichMenuname"),
                        disabled: _vm.editMode
                      },
                      domProps: { value: _vm.formInput.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.formInput, "name", $event.target.value)
                        }
                      }
                    })
                  ]),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v("\n            " + _vm._s(_vm.$t("__RichMenuText")))
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formInput.chatBarText,
                          expression: "formInput.chatBarText"
                        }
                      ],
                      staticClass: "form-control px-4 mx-3",
                      attrs: {
                        type: "text",
                        placeholder: _vm.$t("__RichMenuText")
                      },
                      domProps: { value: _vm.formInput.chatBarText },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.formInput,
                            "chatBarText",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ]),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v("\n            " + _vm._s(_vm.$t("__background")))
                  ]),
                  _c(
                    "div",
                    {
                      class: {
                        "layout-image-container-1": _vm.isImageClass1,
                        "layout-image-container-2": _vm.isImageClass2
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.imageInput.click()
                        }
                      }
                    },
                    [
                      _c("input", {
                        ref: "imageInput",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          accept: "image/*",
                          disabled: _vm.editMode
                        },
                        on: {
                          change: function($event) {
                            return _vm.onImageChanged($event)
                          }
                        }
                      }),
                      _vm.formInput.imageB64 != ""
                        ? _c("img", {
                            staticStyle: { width: "100%" },
                            attrs: { src: _vm.formInput.imageB64 }
                          })
                        : _vm._e(),
                      _vm.formInput.imageB64 == "" && !_vm.editMode
                        ? _c("span", { staticClass: "layout-image-hint" }, [
                            _vm._v(_vm._s(_vm.$t("__selectImage")))
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mt-1",
                      staticStyle: { "font-size": "11px" }
                    },
                    [_vm._v(_vm._s(_vm.imageSizeHint))]
                  ),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__richMsgLayout")))
                  ]),
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("A")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "A"
                              }
                            },
                            _vm._l(["A", "B", "C", "D", "E", "F"], function(n) {
                              return _c(
                                "div",
                                {
                                  key: n,
                                  staticClass: "col-4 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "A"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "40%"
                                      }
                                    },
                                    [_vm._v(_vm._s(n))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("B")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "B"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "col-12 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "B"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "10%"
                                      }
                                    },
                                    [_vm._v(_vm._s("A"))]
                                  )
                                ]
                              ),
                              _vm._l(["B", "C", "D"], function(n) {
                                return _c(
                                  "div",
                                  {
                                    key: n,
                                    staticClass: "col-4 layout-unit",
                                    class: {
                                      "layout-selected":
                                        _vm.formInput.layout === "B"
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticStyle: {
                                          "text-align": "center",
                                          "margin-top": "40%"
                                        }
                                      },
                                      [_vm._v(_vm._s(n))]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "container col-4",
                          on: {
                            click: function($event) {
                              return _vm.onLayoutChanged("C")
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "row layout-container",
                              class: {
                                "layout-selected": _vm.formInput.layout === "C"
                              }
                            },
                            _vm._l(["A", "B", "C"], function(n) {
                              return _c(
                                "div",
                                {
                                  key: n,
                                  staticClass: "col-4 layout-unit",
                                  class: {
                                    "layout-selected":
                                      _vm.formInput.layout === "C"
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-top": "40%"
                                      }
                                    },
                                    [_vm._v(_vm._s(n))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ])
                  ]),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__action")))
                  ]),
                  _vm._l(_vm.formInput.areaOptions, function(action, key) {
                    return _c(
                      "div",
                      { key: key, staticClass: "action-container mb-1" },
                      [
                        _c("label", { staticClass: "ml-3 mt-2" }, [
                          _vm._v(_vm._s(key))
                        ]),
                        _c(
                          "div",
                          { staticClass: "row px-4 mt-2" },
                          [
                            _c("md-radio", {
                              staticClass: "ml-3",
                              attrs: {
                                type: "radio",
                                value: "text",
                                disabled: _vm.editMode
                              },
                              model: {
                                value: action.type,
                                callback: function($$v) {
                                  _vm.$set(action, "type", $$v)
                                },
                                expression: "action.type"
                              }
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: action.text,
                                  expression: "action.text"
                                }
                              ],
                              staticClass: "form-control col-10 ml-0",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("__Textcontent"),
                                id: "text-" + key,
                                disabled: _vm.editMode
                              },
                              domProps: { value: action.text },
                              on: {
                                focus: function($event) {
                                  return _vm.onActionTypeChanged($event, key)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(action, "text", $event.target.value)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "row px-4 mt-2" },
                          [
                            _c("md-radio", {
                              staticClass: "ml-3",
                              attrs: {
                                type: "radio",
                                value: "uri",
                                disabled: _vm.editMode
                              },
                              model: {
                                value: action.type,
                                callback: function($$v) {
                                  _vm.$set(action, "type", $$v)
                                },
                                expression: "action.type"
                              }
                            }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: action.url,
                                  expression: "action.url"
                                }
                              ],
                              staticClass: "form-control col-10 ml-0",
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("__urllinkoptional"),
                                id: "uri-" + key,
                                disabled: _vm.editMode
                              },
                              domProps: { value: action.url },
                              on: {
                                focus: function($event) {
                                  return _vm.onActionTypeChanged($event, key)
                                },
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(action, "url", $event.target.value)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  _c("label", { staticClass: "pt-3 mb-2" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__segment")))
                  ]),
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.formInput.segmentId,
                              expression: "formInput.segmentId"
                            }
                          ],
                          staticClass: "form-control col-5 ml-2",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.formInput,
                                "segmentId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.$t("__segment")) +
                                  "\n                "
                              )
                            ]
                          ),
                          _c("option", { attrs: { value: "richAllUser" } }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.$t("__richAllUser")) +
                                "\n                "
                            )
                          ]),
                          _vm._l(_vm.segmentList, function(segment, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: segment.id } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(segment.name) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ],
                2
              )
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              !_vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.submitRichMenu()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("__CreatenewRichMenu")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e(),
              _vm.editMode
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      staticStyle: { width: "100%" },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.submitRichMenu()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("__saveRichMenu")) +
                          "\n        "
                      )
                    ]
                  )
                : _vm._e()
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }