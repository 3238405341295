<template>
  <table class="table" :class="tableClass">
    <thead class="thead-dark">
      <th>#</th>
      <th style="cursor: pointer;" @click="onClickSegmentNameColumnHeader">
        {{this.$t('__CategoryName')}} {{ getSortOrderLabel(segmentNameColumnSortState) }}
      </th>
      <th>{{this.$t('__Classificationrules')}}</th>
      <th>{{this.$t('__Numberofpeopleincategory')}}</th>
      <th>{{this.$t('__Sendconversation')}}</th>
      <th></th>
    </thead>
    <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>
            <div v-if="item.conditions.length > 0">
              <div class="mb-1" v-for="( condition, condition_index ) in item.conditions" :key="'tag_' + condition_index">
                <ul class="list-group list-group-horizontal" v-for="(subItem, subIndex) in condition.multi_condition" :key="'cond_' + subIndex">
                  <li class="list-group-item" style="width: 150px">
                    <span v-if="subItem.source == 'uservariable'">{{ $t('__variable') }}</span>
                    <span v-if="subItem.source == 'tag'">{{ $t('__label') }}</span>
                    <span v-if="subItem.source == 'followtime'">{{ $t('__FollowAccountTime') }}</span>
                    <span v-if="subItem.source == 'cvkey'">{{ $t('__CvParam') }}</span>
                  </li>
                  <li class="list-group-item" style="width: 150px">
                    <span v-if="subItem.source == 'uservariable'">{{ subItem.uservariable.name }}</span>
                    <span v-if="subItem.source == 'tag'">{{ subItem.tag.name }}</span>
                    <span v-if="subItem.source == 'cvkey'">{{ subItem.value }}</span>
                  </li>
                  <li class="list-group-item" style="width: 100px">
                    <span v-if="subItem.expression == 'exactly_match'">完全一致</span>
                    <span v-if="subItem.expression == 'fuzzy_match'">部分一致</span>
                    <span v-if="subItem.expression == 'has_value'">{{ $t('__segmentcondition') }}</span>
                    <span v-if="subItem.expression == 'no_value'">{{ $t('__segmentnocondition') }}</span>
                    <span v-if="subItem.expression == 'num_range'">數值範圍</span>
                    <span v-if="subItem.expression == 'num_gt'">大於數值</span>
                    <span v-if="subItem.expression == 'num_lt'">小於數值</span>
                    <span v-if="subItem.expression == 'date_range'">日期區間</span>
                  </li>
                  <li class="list-group-item" style="width: 240px" v-if="subItem.expression != 'has_value' & subItem.expression != 'no_value'">
                    <span v-if="subItem.expression == 'exactly_match'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'fuzzy_match'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_gt'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_lt'">{{ subItem.value }}</span>
                    <span v-if="subItem.expression == 'num_range'">{{ subItem.range.start }} ~ {{ subItem.range.end }}</span>
                    <span v-if="subItem.expression == 'date_range'">{{ toDateString(subItem.range.start) }} ~ {{ toDateString(subItem.range.end) }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="item.followTime">
              <span class="mr-3">Follow Date</span><span>{{ toDateString(item.followTime.start) }} ~ {{ toDateString(item.followTime.end) }}</span>
            </div>
            <div v-else-if="item.mode == 'CVKey'">
              <span>orderId: {{ item.cvKey.orderId }}</span>
            </div>
            <span v-else>{{$t('__Custom')}}</span>
          </td>
          <td @click="showUser(item.users)" :class="{pointer: (item.users.length != 0)}">{{ countItem(item.users) }}</td>
          <td>
            <a href="javascript:void(0);" @click="broadcastInSegment(item)">
              <font-awesome-icon icon="bullhorn" />
            </a>
          </td>
          <td>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#segment"
              class="px-2"
              @click="editSegment(item)"
            >
              <font-awesome-icon icon="edit" />
            </a>
            <a href="javascript:void(0)" @click="deleteSegment(item.id)">
              <i class="fas fa-trash text-danger"></i>
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
    <create-segment-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-bind:modalData="modalData"
      :editId="editId"
      :key="componentKey"
      v-if="componentKey"
    ></create-segment-modal>
    <create-push-modal
      :adminId="adminId"
      :jwtToken="jwtToken"
      :accountId="accountId"
      v-bind:modalData="broadcastData"
      :key="pushMessageKey"
      v-if="pushMessageKey"
    ></create-push-modal>
    <!-- add -->
    <div class="btn-follow" @click="createSegment()">
      <a style="color: #FFF;">
        <i class="fas fa-plus"></i>
      </a>
    </div>
  </table>
</template>
<script>
import { apiFunction } from "../Axios/functions";
import { CreatePushModal, CreateSegmentModal } from "../Modals/index";
import { Utility } from "../../../utility";
export default {
  name: "segment-table",
  components: { CreateSegmentModal, CreatePushModal },
  props: {
    columns: Array,
    adminId: String,
    jwtToken: String,
    accountId: String,
    data: Array,
    type: {
      type: String, // striped | hover
      default: "striped"
    },
    newSegment: Object,
    currentNumber: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      segmentId: "",
      modalData: {
        mode:"",
        segmentName: "",
        segmentConditions: [],
        segmentUsers: [],
        followTime: {
          start: "",
          end: ""
        },
        cvKey: {},
        modalBtn:  this.$t('__setup'),
        modalSuccessMsg: this.$t('__Anewcategoryhasbeensuccessfullycreated')
      },
      broadcastData: {
        broadcastName: "",
        segment: [],
        broadcastUsers: [],
        modalSuccessMsg:  this.$t('__Theappointmentschedulehasbeensuccessfullycreated')
      },
      editId: "",
      componentKey: 0,
      pushMessageKey: 0,
      // テーブルのソート状態（none, asc, desc）
      segmentNameColumnSortState: 'none',
    };
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
    tagColor(color) {
      return this;
    }
  },
  methods: {
    // Number of users
    countItem(users) {
      return users.length;
    },
    // Render modal component each click
    forceRerender() {
      this.componentKey += 1;
    },
    // Render modal component each click
    forceRerenderPush() {
      this.pushMessageKey += 1;
    },
    // Init new name
    // Example: SG00001
    getNewSegmentName() {
      let totalSegment = this.currentNumber;
      let defaultName = (Number(totalSegment) + 1).toString();
      return "SG" + defaultName.padStart(5, "0");
    },
    // Broadcast
    broadcastInSegment(segment) {
      this.forceRerenderPush();
      this.broadcastData.broadcastName = segment.name;
      this.broadcastData.broadcastUsers = segment.users;
    },
    // Show users name when click number of users
    showUser(users) {
      if (users.length == 0) return;
      const userNames = users.map(user => user.userName);
      this.$alert(`${this.$t('__segmentedUsers')}: ${userNames.join(',')}`);
    },
    // Add segment
    createSegment() {
      this.forceRerender();
      this.editId = "";
      this.modalData.mode = "Tags";
      this.modalData.segmentName = this.getNewSegmentName();
      this.modalData.segmentConditions = [];
      this.modalData.segmentUsers = [];
      this.modalData.cvKey = {};
      this.modalData.modalBtn = this.$t('__setup');
      this.modalData.modalSuccessMsg = this.$t('__Anewcategoryhasbeensuccessfullycreated');
    },
    // Edit segment
    editSegment(segment) {
      this.forceRerender();
      this.editId = segment.id;
      this.modalData.mode = segment.mode ? segment.mode : ""
      this.modalData.segmentName = segment.name;
      this.modalData.segmentConditions = Utility.deepCopy(segment.conditions);
      this.modalData.segmentUsers = Utility.deepCopy(segment.users);
      this.modalData.followTime = segment.followTime;
      this.modalData.cvKey = segment.cvKey ? segment.cvKey : {};
      this.modalData.modalBtn = this.$t('__edit');
      this.modalData.modalSuccessMsg = this.$t('__Editsuccessfullyaccessed');
    },
    // Delete segment
    deleteSegment(segmentId) {
      this.segmentId = segmentId;
      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText:  this.$t('__YesIwanttodelete'),
        cancelButtonText:  this.$t('__cancel')
      }).then(result => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            segmentId: this.segmentId
          };

          apiFunction.deleteUserSegment(formData, this.jwtToken).then(data => {
            if (data) {
              this.$fire({
                title:  this.$t('__successfullydeleted'),
                text:  this.$t('__Yourdatahasbeensuccessfullydeleted'),
                type: "success",
                timer: 1500
              }).then(e => {
                this.$router.go();
              });
            } else {
              this.$fire({
                title: this.$t('__failedtodelete'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: "error"
              });
            }
          });
        }
      });
    },
    getSortOrderLabel(order) {
      if (order == 'none') return '';
      return order == 'asc' ? '▲' : '▼';
    },
    onClickSegmentNameColumnHeader() {
      this.segmentNameColumnSortState = (this.segmentNameColumnSortState == 'asc' ? 'desc' : 'asc');
      this.sortTableBySegmentNameColumn();
    },
    sortTableBySegmentNameColumn() {
      if (this.segmentNameColumnSortState == 'none') return;
      const ascFunc = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }
      const descFunc = (a, b) => {
        if (a.name > b.name) return -1;
        if (a.name < b.name) return 1;
        return 0;
      }
      this.data.sort(this.segmentNameColumnSortState == 'asc' ? ascFunc : descFunc);
    },
    toDateString(datetime) {
      return Utility.toDateString(datetime);
    }
  }
};
</script>
<style>
</style>
