var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("div", { staticClass: "text-center" }, [_vm._m(0)])
        : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            { attrs: { id: "myKanban" } },
            _vm._l(_vm.maxLevel + 1, function(index) {
              return _c(
                "div",
                {
                  key: "level" + index,
                  staticClass: "kanban-container",
                  attrs: { id: "level" + index }
                },
                [
                  _c("h5", { staticClass: "text-center" }, [
                    _vm._v("Lv" + _vm._s(index))
                  ]),
                  _c(
                    "draggable",
                    {
                      staticClass: "kanban-board mr-5 p-4 list-group",
                      attrs: { id: index, group: "node" },
                      on: {
                        change: _vm.log,
                        start: function($event) {
                          _vm.drag = true
                        },
                        end: _vm.endpoint
                      },
                      model: {
                        value: _vm.nodes,
                        callback: function($$v) {
                          _vm.nodes = $$v
                        },
                        expression: "nodes"
                      }
                    },
                    _vm._l(_vm.nodes, function(nodeItem) {
                      return _c(
                        "div",
                        {
                          key: nodeItem.nodeId,
                          attrs: {
                            "ghost-class": "ghost",
                            id: nodeItem.nodeId,
                            name: nodeItem.level
                          }
                        },
                        [
                          nodeItem.level == index
                            ? _c(
                                "div",
                                {
                                  class: [
                                    nodeItem.error == 1
                                      ? "error list-group-item"
                                      : "list-group-item"
                                  ]
                                },
                                [
                                  _c("div", { staticClass: "float-right" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "text-danger float-right",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteNode(nodeItem)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "fas fa-trash text-danger"
                                        })
                                      ]
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "column mb-0 node-card",
                                      staticStyle: { padding: "0" },
                                      on: {
                                        contextmenu: function($event) {
                                          $event.preventDefault()
                                          return _vm.$refs.menu.open($event, {
                                            nodeItem: nodeItem
                                          })
                                        },
                                        click: function($event) {
                                          return _vm.editNode(nodeItem, 0)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header item_handle drag_handler"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "1em",
                                                "font-weight": "bolder"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNodeCardSubTitle(
                                                    nodeItem
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header item_handle drag_handler pt-0"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-size": "0.8em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getNodeCardTitle(nodeItem)
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-body item_handle drag_handler"
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "nodeIcon",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.nodeIcon(nodeItem)
                                              )
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn create-node-btn",
                      on: {
                        click: function($event) {
                          return _vm.createNode(index)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("__createNewNode")) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("vue-context", {
        ref: "menu",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var data = ref.data
              return [
                _c("li", [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.copyNode(data.nodeItem)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("__Copycurrenttarget")) +
                          "\n      "
                      )
                    ]
                  )
                ])
              ]
            }
          }
        ])
      }),
      _vm.componentKey
        ? _c("create-edit-node-modal", {
            key: _vm.componentKey,
            attrs: {
              adminId: _vm.adminId,
              jwtToken: _vm.jwtToken,
              accountId: _vm.accountId,
              scenarioId: _vm.scenarioId,
              allNodes: _vm.nodes,
              nodeData: _vm.nodeModal,
              allTags: _vm.allTags,
              allVariables: _vm.allVariables,
              allScenario: _vm.allScenario,
              allSegments: _vm.allSegments,
              crosApiErrs: _vm.crosApiErrs,
              allVarForms: _vm.allVarForms
            },
            on: { childToParent: _vm.onChildClick }
          })
        : _vm._e(),
      _c("create-tag-modal", { on: { newTag: _vm.newTag } }),
      _c("exist-tag-modal", {
        key: _vm.tagComponentKey,
        attrs: { allTags: _vm.allTags },
        on: { addTag: _vm.addTag }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spinner-border" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }