var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-2 mb-3" },
      [
        _c("label", [_vm._v(" " + _vm._s(_vm.$t("__Form")))]),
        _c(
          "a",
          {
            staticClass: "text-info m-1",
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function($event) {
                return _vm.showCreateFormModal()
              }
            }
          },
          [_c("i", { staticClass: "fas fa-plus text-info" })]
        ),
        _vm._l(_vm.allVarFormIds, function(varFormId, index) {
          return _c("div", { key: index, staticClass: "input-group mb-1" }, [
            _c("div", { staticClass: "input-group-prepend" }, [
              _c("div", { staticClass: "input-group-text" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedFormIds,
                      expression: "checkedFormIds"
                    }
                  ],
                  attrs: { type: "checkbox", id: "varFormId" + index },
                  domProps: {
                    value: varFormId,
                    checked: Array.isArray(_vm.checkedFormIds)
                      ? _vm._i(_vm.checkedFormIds, varFormId) > -1
                      : _vm.checkedFormIds
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.checkedFormIds,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = varFormId,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.checkedFormIds = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.checkedFormIds = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.checkedFormIds = $$c
                      }
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "py-2 border rounded col" }, [
              _c("span", [_vm._v(_vm._s(_vm.varFormNameById(varFormId)))]),
              _c(
                "a",
                {
                  staticClass: "pull-right",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.removeVarForm(varFormId)
                    }
                  }
                },
                [_c("i", { staticClass: "fas fa-trash text-danger mx-1" })]
              ),
              _c(
                "a",
                {
                  staticClass: "pull-right",
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function($event) {
                      return _vm.editVarForm(varFormId)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fas fa-pencil-alt cursor-pointer mx-1"
                  })
                ]
              )
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }