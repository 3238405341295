import CreatePushModal from "./CreatePush.vue";
import CreateSegmentModal from "./CreateSegment.vue";


let modals = {
    CreatePushModal,
    CreateSegmentModal
}

export default modals;

export {
    CreatePushModal,
    CreateSegmentModal
}
