import axios from 'axios';

export const apiFunction = {
  // Download CSV from chat history
  // ***required fields
  // **
  // "adminId": "string",
  // "accountId": "string"
  // "startTime": "datetime"
  // "endTime": datetime""
  // **
  // ***
  getChatHistoryCsv: function (formData, jwtToken) {
    return axios({
      method: "POST",
      url: process.env.VUE_APP_API_ENDPOINT + "getChatHistoryCsv",
      data: formData,
      headers: {
        Authorization: `Bearer ${jwtToken}`
      },
    }).then(res => {
      return res;
    }).catch(e => {
      console.log(e.response);
      return {error: e.response ? e.response : "null"}
    });
  },
}
